body {
  background-color: white;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &__row {
    flex-wrap: nowrap !important;
    min-width: 100%;
    min-height: 197px;
    overflow-x: auto;
  }

  &__item {
    display: flex;
    flex-direction: column;

    min-width: 315px;
    max-width: 315px;
    min-height: 197px;
    max-height: 197px;
    background-color: #f6c344;
    border-radius: 10px;
    padding: 16px;
    margin-right: 16px;
    margin-bottom: 16px;

    &__title {
      font-size: 22px;
      font-weight: 500;
    }

    &__title.stroke {
      background-color: white;
      border-radius: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }

    .details {
      font-size: 14px;
      font-weight: 400;
    }
    .actions {
      margin-top: auto;
      display: flex;
      flex-direction: column;
    }
  }

  &__item.img-bg {
    background-size: cover !important;
    background-position: center !important;
  }

  .blue {
    color: white;
    background-color: #0099FF;
  }

  .orange {
    color: white;
    background-color: #FF7744;
  }

  .green {
    color: white;
    background-color: #66BB66;
  }

  .purple {
    color: white;
    background-color: #BB66CC;
  }

  .yellow {
    color: white;
    background-color: #e1d652;
  }

  .blue-outline {
    color: #0099FF;
    background-color: white;
    border: 1px solid #0099FF;
  }
}

.cards::-webkit-scrollbar {
  display: none;
}

.cards-sm {
  display: flex;
  min-width: 100%;
  min-height: 100px;
  overflow-x: auto;

  &__item {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;

    min-width: 170px;
    max-width: 170px;
    min-height: 100px;
    max-height: 100px;
    background-color: #DDDDDD;
    color: white;
    border-radius: 10px;

    font-size: 26px;
    font-weight: 500;

    margin-right: 16px;
  }
}

.speech {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .img {
    width: 60px;
    height: 60px;
    background-color: red;
  }

  //.speech {
  //  flex-grow: 1;
  //  margin-left: 20px;
  //  background-color: green;
  //  padding: 8px;
  //}

  .speech-bubble {
    margin-left: 5px;
    padding: 16px;
    position: relative;
    background: #ffffff;
    border-radius: .4em;
    display: flex;
    align-items: center;
    border: 1px solid #000000;
    white-space: pre-wrap;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-right-color: #ffffff;
      border-left: 0;
      margin-top: -10px;
      margin-left: -10px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 11px solid transparent;
      border-right-color: #000000;
      border-left: 0;
      margin-top: -11px;
      margin-left: -11px;
    }

  }
}
