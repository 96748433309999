.test {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin-bottom: 16px;

    border-radius: 15px;
    background-color: #F5F5F5;

    &__text {
      font-weight: 600;

      .secondary {
        color: #AFAFAF;
        font-weight: 400;
      }
    }

    &__progress {
      font-weight: 600;
    }

    .progress {
      flex: 2;
      margin-left: 16px;
    }
  }
}

.content {
  min-height: calc(100vh - 168px);
  max-height: calc(100vh - 168px);
}

.test-question {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  overflow: hidden;
  border-radius: 15px;
  background-color: white;

  img {
    height: calc((100vh - 168px) * 0.5);
    max-width: 50%;
    object-fit: cover;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    img {
      max-width: 100%;
    }
  }

  &__detail {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__chat {

    padding: 16px;
    background-color: #F5F5F5;
    flex-grow: 1;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

  }

}

.btn-answer {
  border-radius: 15px;
  background-color: #F5F5F5;

  font-weight: 500;
}
