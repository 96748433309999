.subtle-test {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin-bottom: 16px;

    border-radius: 15px;
    background-color: #F5F5F5;

    &__text {
      font-weight: 600;

      .secondary {
        color: #AFAFAF;
        font-weight: 400;
      }
    }

    &__progress {
      font-weight: 600;
    }

    .progress {
      flex: 2;
      margin-left: 16px;
    }

  }
}

.subtle-test-map {
  display: flex;
  flex-direction: column;

  .map {
    flex-grow: 1;
    display: flex;
    overflow: hidden;

    margin-top: auto;
    margin-bottom: auto;

    img {
      max-width: 100%;
      min-width: 100%;
      //max-height: 100%;
      object-fit: contain;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .btn {
      min-width: 100px;
    }
  }
}

.subtle-test-question {
  display: flex;
  flex-direction: row;

  .image {
    width: 50%;
  }

  .answers-block {
    display: flex;
    flex-direction: column;

    width: 50%;
    padding-left: 16px;

    .title {
      text-align: center;
    }
    .answers {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-grow: 1;

      .list-group {
        flex-grow: 1;
      }

    }
    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.subtle-test-results {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;

    .points {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    margin-top: 50px;

    .radar {
    }
    .tabs {
      margin-left: 20px;
      flex-grow: 1;

      &-content {
        padding-top: 20px;
      }
    }

  }
}
