.nursing {
  height: 100vh;

  @media screen and (max-width: 576px) {
    height: unset;
  }

  &__navbar {
    background-color: #FFCE8D;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin-bottom: 16px;

    border-radius: 15px;
    background-color: #FFF5DE;

    &__text {
      font-weight: 400;
      color: #707070;

      .secondary {
        color: #707070;
        font-weight: 400;
      }
    }

    &__progress {
      font-weight: 600;
    }

    .progress {
      flex: 2;
      margin-left: 16px;
    }

  }
}

.nursing-landing {
  &__banner {
    position: relative;
    font-size: 25px;
    font-weight: bold;
    color: #FFA937;
    text-align: center;
    border: 1px #FFA937 solid;
    border-radius: 25px;
    padding-left: 200px;
    padding-right: 200px;
    padding-top: 100px;
    padding-bottom: 60px;
    margin-top: 100px;

    @media screen and (max-width: 576px) {
      display: flex;
      flex-direction: column;
      padding: 20px;
      margin-top: 50px;
    }

    img {
      position: absolute;
      top: -80px;
      width: 324px;
      height: 159px;
      @media screen and (max-width: 576px) {
        position: relative;
        top: unset;
        width: 222px;
        height: 92px;
      }
    }
  }

  &__action {
    background-color: #6FF381 !important;
    font-size: 25px !important;
    font-weight: bold !important;
    border: unset !important;
    border-radius: 25px !important;
    min-width: 40vw;
    min-height: 50px;
    margin-top: 80px;

    @media screen and (max-width: 576px) {
      width: 90vw;
    }
  }
}

.nursing-question {
  flex: 1;
  display: flex;
  flex-direction: row;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border: 5px #FFCE8D solid;
    border-radius: 5px;
    font-size: 35px;
    font-weight: normal;
    color: #707070;
    padding: 20px;

    @media screen and (max-width: 576px) {
      font-size: 25px;
    }

  }

  &__image {
    width: 50%;
  }

  &__answers-block {
    flex: 1;
    display: flex;
    flex-direction: column;

    &__title {
      text-align: center;
      color: #707070;
      font-size: 20px;

    }

    &__answers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      min-height: 200px;
      margin-top: 20px;

      @media screen and (max-width: 576px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      &__item {
        flex: 1 1 0;
        height: 100%;
        border: 1px #707070 solid;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        font-size: 20px;
        color: #707070;
        padding: 20px;
        text-align: center;
        cursor: pointer;

        @media screen and (max-width: 576px) {
          margin-right: unset;
          margin-bottom: 10px;
          font-size: 16px;
          padding: 10px;
          height: unset;
          width: 100%;
        }
      }
    }
    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: auto;
    }
  }
}

.nursing-anket {
  &__title {
    color: #707070;
    font-size: 25px;
    text-align: center;
    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }

  &__label {
    color: #707070;
    font-size: 35px;

    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }

  &__control {
    background-color: red;
  }

  &__action {
    background-color: #6FF381 !important;
    font-size: 25px !important;
    font-weight: bold !important;
    border: unset !important;
    border-radius: 25px !important;
    min-width: 40vw;
    min-height: 50px;
    margin-top: 20px;
  }

}

.nursing-results {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: #FFF8DD;
    margin-top: 80px;

    @media screen and (max-width: 576px) {
      background-color: unset;
      margin-top: unset;
    }

    &__name {
      margin-top: 40px;
      color: #707070;
      font-size: 20px;
    }

    &__type-container {
      margin-top: 20px;
      padding: 30px;
      border: 8px #FBC459 solid;
      border-radius: 15px;
      background-color: white;

      min-width: 400px;
      max-width: 400px;

      @media screen and (max-width: 576px) {
        min-width: unset;
        max-width: unset;
        min-height: 400px;
        width: 90%;
      }

      &__title {
        color: #FECE2A;
        font-size: 30px;
        white-space: pre-wrap;
        word-break: break-all;
        text-align: center;
      }

      &__image-container {
        position: relative;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 576px) {
          position: absolute;
          left: 0;
        }

        &__bubble {
          position: absolute;
          right: -320px;
          bottom: 0;
          background-color: #84F642;
          border-radius: 10px;
          width: 300px;
          color: #707070;
          padding: 20px;

          @media screen and (max-width: 576px) {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 40vw;
            right: -45vw;
            font-size: 12px;
            border-radius: 10px;
            padding-top: 8px;
            padding-right: 8px;
            padding-bottom: 8px;
            padding-left: 15px;
          }

          &__arrow {
            position: absolute;
            top: 40%;
            left: -25px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 40px 20px 0;
            border-color: transparent #84F642 transparent transparent;
          }
        }
      }
    }

    &__banner {
      margin-top: 30px;
      padding: 20px;
      margin-bottom: 20px;
      border: 1px #707070 solid;
      border-radius: 15px;
      background-color: white;
      font-size: 20px;
      color: #707070;
      white-space: pre-wrap;
      text-align: center;
      max-width: 500px;
      min-width: 500px;

      @media screen and (max-width: 576px) {
        max-width: unset;
        min-width: unset;
        width: 80%;
        font-size: 15px;
      }
    }

    &__text {
      margin-top: 30px;
      color: #707070;
      max-width: 500px;
      min-width: 500px;
      font-size: 20px;
      margin-bottom: 30px;

      @media screen and (max-width: 576px) {
        max-width: unset;
        min-width: unset;
        width: 90%;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    &__message {
      display: flex;
      flex-direction: row;
      max-width: 500px;
      min-width: 500px;

      margin-top: 40px;
      margin-bottom: 20px;

      @media screen and (max-width: 576px) {
        margin-top: 10px;
        max-width: unset;
        min-width: unset;
        width: 90%;
      }

      &__title {
        color: #FECE2A;
        font-size: 18px;
      }
      &__description {
        color: #707070;
        font-size: 18px;
      }
      &__image {
        margin-right: 20px;
      }
    }

    &__sns {
      display: flex;
      flex-direction: row;
      max-width: 500px;
      min-width: 500px;
      padding-top: 50px;

      @media screen and (max-width: 576px) {
        margin-top: 10px;
        max-width: unset;
        min-width: unset;
        width: 90%;
      }

      &__title {
        color: #FECE2A;
        font-size: 18px;
      }
      &__image {
        margin-right: 20px;
      }
    }

    &__block1 {
      white-space: pre-wrap;
      word-break: break-all;

      max-width: 500px;
      min-width: 500px;
      margin-top: 50px;
      color: #707070;

      border: 1px solid gray;
      padding: 10px;

      @media screen and (max-width: 576px) {
        margin-top: 20px;
        max-width: unset;
        min-width: unset;
        width: 90%;
      }
    }
  }


}
