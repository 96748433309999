.lesson {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin-bottom: 16px;

    border-radius: 15px;
    background-color: #F5F5F5;

    &__text {
      font-weight: 600;

      .secondary {
        color: #AFAFAF;
        font-weight: 400;
      }
    }

    &__progress {
      font-weight: 600;
    }

    .progress {
      flex: 2;
      margin-left: 16px;
    }

  }
}

.content {
  min-height: calc(100vh - 168px);
  max-height: calc(100vh - 168px);
}

.practice-question {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  overflow: hidden;
  border-radius: 15px;
  background-color: white;

  img {
    height: calc((100vh - 168px) * 0.5);
    max-width: 50%;
    object-fit: cover;
  }

  img.description {
    height: calc((100vh - 168px) * 0.2);
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    img {
      max-width: 100%;
    }
  }

  &__detail {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__chat {

    padding: 16px;
    background-color: #F5F5F5;
    flex-grow: 1;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

  }

  &__kibin {
    padding: 16px;
    background-color: #F5F5F5;
  }
}

.practice-question-answers {
  height: calc((100vh - 168px) * 0.3);

  .header {
    border-radius: 15px;
    background-color: #0F80B4;
    color: white;
    font-weight: 500;
  }

  .items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .item {
      width: calc((100% - 16px) / 3);
      overflow: hidden;

      border-radius: 15px;
      background-color: white;


      img {
        object-fit: contain;
        max-height: calc((100vh - 240px) * 0.25);
      }

      .texts {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 16px;
        font-size: 17px;

        .reading {
          color: #AFAFAF;
          font-size: 13px;
        }
      }
    }
  }

}



.answer-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px;

  .header {
    border-radius: 15px;
    background-color: #F5F5F5;

    font-weight: 500;
  }
}

.incorrect-description {
  //overflow: hidden;
  //
  //

  .texts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      color: black;
      font-weight: 500;
      padding: 16px;
    }

    .text {
      padding: 16px;
      border-radius: 15px;
      background-color: white;
    }
  }
}
