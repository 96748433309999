.subtle-content {
  display: flex;
  flex-direction: row;
  margin-top: 50px;

  .radar {
  }
  .tabs {
    margin-left: 20px;
    flex-grow: 1;

    &-content {
      padding-top: 20px;
    }
  }

}
