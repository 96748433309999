.comic-spirit-container {
  display: flex;
  min-height: calc(100vh);
  padding-top: 56px;
  background-color: white;

  @media screen and (max-width: 576px) {
    height: unset;
    min-height: unset;
  }
}

.comic-spirit-map {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &-header {
    text-align: center;
  }

  &-sub-header {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;
    @media screen and (max-width: 576px) {
      flex: unset;
      max-width: unset;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      margin-top: unset;
      margin-bottom: unset;
    }
  }

  &-image {
    flex: 0 0 50%;
    max-width: 50%;
    height: calc(50vw);
    background-size: contain !important;
    background-position: center !important;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 576px) {
      flex: unset;
      max-width: unset;
      width: calc(90vw);
      height: calc(90vw);
    }
  }

  &-introduction {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    max-width: 50%;
    flex-wrap:nowrap;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 576px) {
      flex: unset;
      max-width: unset;
      margin-top: unset;
      margin-bottom: 16px;
    }

    p {
      word-break: break-all;
      padding-left: 16px;
      @media screen and (max-width: 576px) {
        padding-left: unset;
      }
    }
    &-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .btn {
        min-width: 100px;
      }
    }
  }
}

.comic-spirit-page {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;


  &-image {
    display: flex;
    flex: 1;

    background-size: contain !important;
    background-position: center !important;
  }

  &-actions {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 576px) {
      top: unset;
      bottom: 8px;
    }
    &-action {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 60px;
      height: 60px;
      border-radius: 30px;
      overflow: hidden;

      cursor: pointer;


      background-color: lightgray;
      margin-left: 20px;
      margin-right: 20px;
    }

    &-action.inactive {
      cursor: auto;
      background-color: transparent;
      svg {
        color: transparent;
      }
    }
  }

}
