.comic-life-container {
  display: flex;
  min-height: calc(100vh);
  padding-top: 56px;
  background-color: white;

  @media screen and (max-width: 576px) {
    height: auto;
  }
}

.comic-life-map {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &-header {
    text-align: center;
  }

  &-sub-header {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;
    @media screen and (max-width: 576px) {
      flex: unset;
      max-width: unset;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      margin-top: unset;
      margin-bottom: unset;
    }
  }

  &-image {
    flex: 0 0 50%;
    max-width: 50%;
    height: calc(50vw);
    background-size: contain !important;
    background-position: center !important;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 576px) {
      flex: unset;
      max-width: unset;
      width: calc(90vw);
      height: calc(90vw);
    }
  }

  &-introduction {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    max-width: 50%;
    flex-wrap:nowrap;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 576px) {
      flex: unset;
      max-width: unset;
      margin-top: unset;
      margin-bottom: 16px;
    }

    p {
      word-break: break-all;
      padding-left: 16px;
      @media screen and (max-width: 576px) {
        padding-left: unset;
      }
    }
    &-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .btn {
        min-width: 100px;
      }
    }
  }
}

.comic-life-pages {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &-title {
    text-align: center;
  }

  &-item {
    margin-left: auto;
    margin-right: auto;

    &-image {
      width: calc(50vw);
      height: calc(50vw);
      background-size: contain !important;
      background-position: center !important;

      @media screen and (max-width: 576px) {
        width: calc(90vw);
        height: calc(90vw);
      }
    }
  }

  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    .btn {
      min-width: 100px;
    }
  }

}

.comic-life-situation {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &-header {
    text-align: center;
  }

  &-content {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      margin-top: unset;
      margin-bottom: unset;
    }
  }

  &-image {
    flex: 0 0 50%;
    max-width: 50%;
    height: calc(50vw);
    background-size: contain !important;
    background-position: center !important;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 576px) {
      flex: unset;
      max-width: unset;
      width: calc(90vw);
      height: calc(90vw);
    }
  }

  &-description {
    display: flex;
    flex-direction: column;
    flex: 0 0 50%;
    max-width: 50%;
    flex-wrap:nowrap;
    margin-top: auto;
    margin-bottom: auto;

    @media screen and (max-width: 576px) {
      flex: unset;
      max-width: unset;
      margin-top: unset;
      margin-bottom: 16px;
    }

    p {
      word-break: break-all;
      padding-left: 16px;
      @media screen and (max-width: 576px) {
        padding-left: unset;
      }
    }
    &-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .btn {
        min-width: 100px;
      }
    }
  }
}
