.dropdown-item {
  .icon-hover {
    display: none;
  }
  .icon-danger {
    color: #6c757d !important;
    &:hover {
      color: #dc3545 !important;
    }
  }
  &:hover {
    .icon-hover {
      display: block;
    }

  }
}

.admin-comic-spirit-image {
  background-size: contain !important;
  background-position: center !important;
}
