.answer-reaction-japanese-filling-bubble {
  position: relative;
  background: #e7e9ec;
  border-radius: .4em;
  padding: 16px;
  margin-top: 20px;
}

.answer-reaction-japanese-filling-bubble:after {
  content: '';
  position: absolute;
  top: 0;
  left: 100px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-bottom-color: #e7e9ec;
  border-top: 0;
  border-left: 0;
  margin-left: -10px;
  margin-top: -20px;
}
