.question-chat-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 8px;

  &__name {
    padding-top: 4px;
    text-align: center;
    color: #a3a3a3;
    font-size: 12px;
  }

  &__speech {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin-left: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    border-radius: 15px;
    //border: 1px solid lightgray;

    font-size: 17px;

    width: 100%;

    .reading {
      color: #AFAFAF;
      font-size: 13px;
    }
  }

  &__speech.kibin {
    background-color: rgba(15, 137, 193, 0.53);
    color: white;
  }

  img {
    background-color: white;
    border-radius: 50%;
  }
}
